<template>
  <list title="brands" class="wa__large">
    <template #header-btn>
      <block-button
        height="24"
        class="px-10 btn__size__18 site__button add__mode width-200 mt-n1"
        text="ADD"
        icon="WMi-plus-linear"
        @click.native="$_openModal('brandModal')"
      ></block-button>
    </template>
    <hr class="mt-0" />
    <data-iterator
      :items="getBrands"
      :is-filtered="isFilteredBrand"
      @pagination="changePagination"
      :pagination="getPaginationBrand"
      :loading="getBrandLoading"
      class="row mt-0 px-3"
    >
      <template v-slot:loading>
        <loading />
      </template>
      <template #no-items>
        <!-- fix component -->
        <!-- <no-items :src="$_staticPath('images/global/animated/product.gif')" route-name="AddProduct" textFa=" هنوز هیچ محصولی ثبت نکرده اید! " textEn=" No Products Yet! " themeColor="product" btnText="ثبت اولین  محصول" btnIcon="plus" /> -->
        <no-items />
      </template>
      <template #no-results>
        <!-- fix component -->
        <!-- <no-items :src="$_staticPath('images/global/animated/no-result.gif')" textFa=" متاسفانه محصولی مطابق با فیلتر های شما پیدا نکردیم! " textEn=" Sorry, No Product! " themeColor="product" btnText=" حذف فیلتر ها " /> -->
        <no-items />
      </template>

      <template v-slot:items="{ item }">
        <brand-item :key="item.id" :brand="item" />
      </template>
    </data-iterator>
  </list>
</template>

<script>
import BrandItem from "./Item";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    BrandItem,
  },
  computed: {
    ...mapGetters("brand", [
      "getBrands",
      "getPaginationBrand",
      "isFilteredBrand",
      "getBrandLoading",
    ]),
  },
  methods: {
    ...mapActions("brand", ["loadBrands"]),
    ...mapMutations("brand", ["SET_PAGINATION"]),
    changePagination(pagination) {
      if (this.getPaginationBrand.page !== pagination.page) {
        this.SET_PAGINATION(pagination);
        this.loadBrands();
      }
    },
  },
  created() {
    this.loadBrands();
  },
};
</script>
