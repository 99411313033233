<template>
  <v-form @submit.prevent="submit" class="h-100 mb-3">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12" class="pt-4">
          <div class="h-35">
            <v-text-field
              :label="$_t('brandName')"
              color="SonicSilver"
              v-model="filter.name.val"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-35">
            <v-autocomplete
              class="pt-0 mt-0"
              :items="getLanguages"
              item-value="id"
              item-text="name"
              :label="$_t('language')"
              v-model="filter.language_id.val"
              color="SonicSilver"
              outlined
              dense
            ></v-autocomplete>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            :text="$_t('clear')"
            color="black"
            @click.native="clearFilter"
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            type="submit"
            :text="$_t('execute')"
          ></block-button>
        </v-col> </v-row
    ></list>
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
const defaultFilter = {
  name: {
    type: "like",
    val: null,
  },
  language_id: {
    type: "in",
    val: null,
  },
};
export default {
  data() {
    return {
      filter: {},
    };
  },
  methods: {
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("brand", ["loadBrands"]),
    ...mapMutations("brand", ["SET_FILTER_BRAND","SET_IS_FILTERED_BRAND"]),
    submit() {
      this.SET_IS_FILTERED_BRAND(true);
      this.SET_FILTER_BRAND(this.filter);
      this.loadBrands();
    },
    clearFilter() {
      this.SET_IS_FILTERED_BRAND(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_BRAND(cloneDeep(this.filter));
      this.loadBrands();
    },
    loadPastFilter(){
      this.filter = cloneDeep({...defaultFilter , ...this.getFiltersBrand})
    }
  },
  computed: {
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("brand", ["getFiltersBrand"]),
  },
  created() {
    this.loadLanguages();
    this.loadPastFilter();
  },
};
</script>
<style scoped>
/* filter form  */
.h-35 {
  height: 35px;
}
</style>
