<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__hiring__requests">
          <v-col cols="12" md="2"><BrandFilter /></v-col>
          <v-col cols="12" md="10"><BrandItems /></v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <div class="text-center">
      <BrandModal v-if="isModal('modal_brandModal')" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import BrandItems from "../components/Brand/Items.vue";
import BrandFilter from "../components/Brand/Filter";
import BrandModal from "../components/Brand/BrandModal.vue";
export default {
  components: {
    BrandItems,
    BrandFilter,
    BrandModal,
  },
  computed: {
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapMutations('brand', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script>