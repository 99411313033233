<template>
  <v-col cols="3">
    <div class="wa__brand__item">
      <div class="wa__brand__item--item">
        <div class="mb-4">
          <div class="w-100 h-100">
            <img :src="brand.thumbnail" alt="brand image" class="w-100 h-100" />
          </div>
        </div>
        <div class="wa__brand__item--brand">
          {{ brand.name }}
        </div>
        <div class="wa__brand__item--button d-flex">
          <div class="mr-1">
            <circle-button
              icon="WMi-pencil"
              color="black"
              @click.native="$_openModal('brandModal', { model: brand })"
            ></circle-button>
          </div>
          <circle-button
            icon="WMi-trash"
            color="red"
            bg-color="bgLightPink"
            @click.native="deleteMessage"
          ></circle-button>
        </div> 
      </div>
    </div>
  </v-col>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import toast from "@/utils/toast";
export default {
  props: {
    brand: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("brand", ["deleteBrand","loadBrands"]),
    async deleteMessage(){
      toast.question(this.$_trans("toast.del_brand_item_msg"), this.$_trans("toast.del_brand_item_title"), 
        async ()=>{
          await this.deleteBrand(this.brand.id)
          if(!this.getBrands.length){
            this.loadBrands()
          }
        })
    }
  },  
  computed: {
    ...mapGetters("brand", ["getBrands"]),
  },
};
</script>
<style scoped>
.wa__brand__item--item {
  border: 1px solid var(--color-light-gray);
}
/* button */

.wa__brand__item--button.d-flex {
  justify-content: center;
  margin: 5px 0;
}

/* brand name */
.wa__brand__item--brand {
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat";
}
</style>