<template>
  <basic-modal @open="openModal" width="650" transition="slide-x-transition">
    <template #header>
      <divider title="NEW BRAND" sub-title="NEW FRIEND" size="small"></divider>
    </template>
    <template #default>
      <div class="mt-4 px-12">
        <image-cropper
          :url.sync="form.thumbnail"
          v-model="fileForm"
          label="brand image"
        />
        <div class="mt-3">
          <v-text-field
            v-model="form.name"
            label="BRAND NAME"
            color="SonicSilver"
            class="no-error-msg"
            outlined
            dense
          ></v-text-field>
        </div>
        <v-row class="my-2">
          <v-col cols="12">
            <v-select
              :items="getLanguages"
              item-value="id"
              item-text="name"
              label="LANGUAGE"
              color="SonicSilver"
              class="no-error-msg"
              v-model="form.language_id"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <div class="h-40">
          <v-text-field
            v-model="form.link"
            label="LINK"
            color="SonicSilver"
            class="no-error-msg"
            outlined
            dense
          ></v-text-field>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-7 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel px-0 btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="24"
          class="btn__modal--assign ml-5 site__button width-auto btn__size__16"
          :text="(form.id ? 'update' : 'add') + ' the brand'"
          icon="WMi-plus"
          :loading="requestLoading"
          @click.native="addBrand"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { makeid } from "@/utils/math";
import ImageCropper from "@/components/Global/Input/ImageCropper";
import FileRepository from "../../abstraction/repository/FileRepository";
const RANDOM_TOKEN = makeid(50);
export default {
  components: {
    ImageCropper,
  },
  data() {
    return {
      requestLoading: false,
      form: {
        thumbnail: "https://via.placeholder.com/600x600",
        file_batch_id: RANDOM_TOKEN,
      },
      fileForm: {
        batch_id: RANDOM_TOKEN,
        model_name: "brand",
        collection_name: "main_image",
        crop: {},
      },
    };
  },
  name: "modal_brandModal",
  methods: {
    openModal({ model }) {
      if (model) {
        this.form = { ...model };
      }
    },
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("brand", ["storeBrand"]),
    ...mapActions("brand", ["updateBrand"]),
    async addBrand() {
      try {
        this.requestLoading = true;
        if (this.fileForm.file) {
          let repository = new FileRepository();
          await repository.store(this.fileForm);
        }

        if (this.form.id) {
          await this.updateBrand(this.form);
        } else {
          await this.storeBrand(this.form);
        }
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters("language", ["getLanguages"]),
  },
  created() {
    this.loadLanguages();
  },
};
</script>
<style scoped>
.v-input {
  padding-top: 0;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
  letter-spacing: 3px;
}

.h-40 {
  height: 40px;
}
</style>
